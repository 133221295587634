import CryptoJS from "crypto-js";

export function aes256Decrypt(encodedText: string) {
  try {
    // const decodedURI = decodeURIComponent(encodedText);
    // base64 디코딩
    const decodedData = CryptoJS.enc.Base64.parse(encodedText);

    // IV와 암호문 추출
    const iv = CryptoJS.lib.WordArray.create(decodedData.words.slice(0, 4));
    const cipherText = CryptoJS.lib.WordArray.create(
      decodedData.words.slice(4)
    );

    // 복호화
    const decrypted = CryptoJS.AES.decrypt(
      CryptoJS.lib.CipherParams.create({
        ciphertext: cipherText,
      }),
      CryptoJS.enc.Utf8.parse(process.env.NEXT_PUBLIC_AES_KEY as string),
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    // UTF-8 문자열로 디코딩하여 반환
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    console.error(err);
  }
}
