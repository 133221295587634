import { useMutation } from "react-query";
import { Axios } from "../utils/axios";

import { useAtom } from "jotai";
import { isLoggedInAtom } from "@/components/AuthProvider";

// type KakaoUserInfoScope =
//   | "profile_nickname"
//   | "profile_image"
//   | "account_email"
//   | "name"
//   | "phone_number"
//   | "gender"
//   | "birthyear"
//   | "friends"
//   | "";
// 이용 중 동의 항목
// age_range?: string;
// birthday?: string;
// account_ci?: string;

// interface KakaoLoginParams {
//   redirectUri?: string;
//   scope?: KakaoUserInfoScope;
//   throughTalk?: boolean;
//   prompt?: "login" | "none" | "create" | "select_account";
//   loginHint?: string;
//   serviceTerms?: string;
//   state?: string;
//   nonce?: string;
// }

export const useKakaoAuthorize = () => {
  const commonKakaoAuthorize = () => {
    window?.Kakao?.Auth.authorize({
      redirectUri: window.location.origin + "/login/",
      prompt: "",
    });
  };

  return {
    commonKakaoAuthorize,
  };
};

export const useServiceServerKakaoLogin = (redirectTo?: string) => {
  const [isLoggedIn] = useAtom(isLoggedInAtom);

  return useMutation({
    mutationFn: async ({
      kakaoAuthorizationCode,
    }: {
      kakaoAuthorizationCode: string;
    }) => {
      /** 서비스에 따른 로그인 처리 */
      const initial_service = process.env.NEXT_PUBLIC_SERVICE || "bugo";

      if (!kakaoAuthorizationCode) return;
      if (isLoggedIn) return;

      const resposne = await Axios.post(`/users/kakao_login/`, {
        initial_service,
        authorization_code: kakaoAuthorizationCode,
      });

      return resposne;
    },
  });
};
