import { isLoggedInAtom } from "@/components/AuthProvider";
import {
  useKakaoAuthorize,
  useServiceServerKakaoLogin,
} from "@/hooks/useKakaoLogin";
import { aes256Decrypt } from "@/utils/aes256Decrypt";

import { getCookie, removeCookie, setCookie } from "goi_common";
import { useSetAtom } from "jotai";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export default function LoginPage() {
  const router = useRouter();
  const [isKakaoAutoLogin, setIsKakaoAutoLogin] = useState<boolean>();
  const setIsLoggedIn = useSetAtom(isLoggedInAtom);

  const { mutate } = useServiceServerKakaoLogin(
    router.query.redirectTo as string
  );

  const { commonKakaoAuthorize } = useKakaoAuthorize();

  useEffect(() => {
    /** 초기방문이 아니면 cookie를 해줌 */
    const flag = getCookie("kakao_auto_login_handled");
    const isKakaoWebView = navigator.userAgent.includes("KAKAOTALK");
    const kakaoAuthorizationCode = router.query.code;

    if (flag && kakaoAuthorizationCode && isKakaoWebView) {
      setIsKakaoAutoLogin(true);
      return;
    }
    /** 무한 로그인 막는 곳이라는데... 우선은 주석 
    if (!flag && isKakaoWebView) {
      setIsKakaoAutoLogin(true);
      if (!flag && !kakaoSuccessCode && isKakaoWebView && !isLoggedIn) {
        handleKakaoAuth();
        setCookie(cooKeys.kakao_auto_login_handled, "YES");
      }
    }
    */
  }, []);

  /** 로그인 되어 있는 경우 로그인 페이지 진입 막으려면 여기 활성화 
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  */

  useEffect(() => {
    const { redirectTo, code: kakaoAuthorizationCode } = router.query as {
      redirectTo?: string;
      code?: string;
    };

    /** 로그인 되어 있는 경우 로그인 페이지 진입 막으려면 여기 활성화 
    if (isLoggedIn) {
      router.push("/");
      return;
    }
    */

    /** 로그인이되어있지 않은데 redirectTo가 있으면 setCookie해주고 login page에서 직접 kakao server로부터 code를 받고
    서비스 서버로 token을 보내 서비스 로그인을 진행  */

    if (redirectTo) {
      setCookie("login_redirect_to", redirectTo);
      commonKakaoAuthorize();
      return;
    }

    /** 로그인이 되어있지 않은데 redirectTo가 없고 code만 가지고 있다면 그것은 방금 따끈따끈한 로그인을 진행한 것*/

    if (kakaoAuthorizationCode) {
      mutate(
        { kakaoAuthorizationCode },
        {
          onSuccess: (response) => {
            const kakaoAccessToken = response?.data;

            if (kakaoAccessToken) {
              /** 서버에서 주는 token 복호화 */
              window?.Kakao.Auth.setAccessToken(
                aes256Decrypt(kakaoAccessToken)
              );
              setIsLoggedIn(true);

              const redirectTo = getCookie("login_redirect_to");
              removeCookie("login_redirect_to");

              router.replace(redirectTo || "/");
            }
          },
        }
      );
      return;
    }
  }, [isKakaoAutoLogin, router.isReady]);

  return <></>;
}
